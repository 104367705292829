import { HeadFC, PageProps, graphql, useStaticQuery } from "gatsby";
import React from "react";
import { HeadSEO } from "../components/head";
import "../styles/compraronline.css";
import ecommerceNetworks from "../mocks/ecommerceNetworks";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";


// Definição da tipagem para os dados do GraphQL
interface ImageNode {
    node: {
        name: string;
        childImageSharp?: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
}

interface QueryResult {
    allFile: {
        edges: ImageNode[];
    };
}

// Hook para buscar imagens via GraphQL
const useEcommerceImages = (): ImageNode[] => {
    const data: QueryResult = useStaticQuery(graphql`
     query {
    allFile(filter: { relativePath: { regex: "/ecommerceNetworks/" } }) {
        edges {
            node {
                name
                childImageSharp {
                    gatsbyImageData(width: 200, placeholder: BLURRED)
                }
            }
        }
    }
}

    `);
    return data.allFile.edges;
};

const ComprarOnline: React.FC<PageProps> = () => {
    const images = useEcommerceImages();
    const removeDiacritics = (str: string) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return (
        <div id="comprar-online" className="container py-4">
            <div className="row g-4">
                {ecommerceNetworks.map(({ name, state, url }) => {

                    const imageName = removeDiacritics(name.toLowerCase()).replace(/\s+/g, "-");


                    const imageNode = images.find(edge => edge.node.name === imageName);
                    const image = imageNode?.node.childImageSharp
                        ? getImage(imageNode.node.childImageSharp)
                        : null;

                    return (
                        <div key={name} className="col-6 col-md-4 col-lg-3 comprar-online">
                            <a href={url} target="_blank" rel="noopener noreferrer" className="d-block text-center border rounded p-3 shadow-sm">
                                {image ? (
                                    <GatsbyImage image={image} alt={name} style={{ width: "200px", height: "200px", objectFit: "contain" }} className="img-fluid mb-2" />
                                ) : (
                                    <p className="text-danger">Imagem não encontrada</p>
                                )}
                                <p className="fw-semibold mb-1">{name}</p>
                                <p className="text-muted small">{state}</p>
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ComprarOnline;

export const Head: HeadFC = () =>
    HeadSEO(
        "Comprar Online Verde Campo - Encontre Nossos Produtos",
        "Compre os produtos Verde Campo online de forma prática e segura. Confira onde encontrar nossas opções saudáveis perto de você!"
    );